import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsofService = () => (
  <Layout pageInfo={{ pageName: "service" }}>
    <SEO title="Terms of Service" description="Please read this Agreement carefully before using the Services." path="termsofService" />
    <div className='terms'>
      <div className='terms-title'>Terms of Service</div>
      <div className='text'>
         Please read this Agreement carefully before using the Services. By using the website, and applications, features and other content (collectively, the "Services"), you signify your assent to become bound by the terms and conditions of this Agreement. If you do not agree to all the terms and conditions of this Agreement, you do not have any right to use the Services. The Services, owned and operated by Shoprises, collect user and usage data to enable website and application owners to send e-mail messages efficiently to consumers based on their behavior. This Agreement applies to all users of the Services. Your use of the Services is an acknowledgment that you understand and agree to be bound by this Agreement, any fees applicable to you, any additional guidelines, Shoprises's Privacy Policy and Anti-Spam Policy, and any future modifications of this Agreement. The Services are accessed by you ("User" or "you") under the following terms and conditions:
      </div>

      <div className='title'>1. ACCESS TO THE SERVICES</div>
      <div className="text">
         Subject to the terms and conditions of this Agreement, Shoprises may provide the Services, which are selected by you, solely for your own use, and not for the use or benefits of any third party. Services shall include, but not be limited to, the offering of any materials displayed or performed on the Site and related features (including, but not limited to text, data, software, graphics, photographs, images, illustrations, audio clips and video clips, also known as the "Content"). Shoprises may change, suspend or discontinue the Services for any reason, at any time, including the availability of any feature or Content. Shoprises may also restrict your access to parts or all of the Services without notice or liability. Shoprises may modify this Agreement at any time by posting a notice on the Services, or by sending you a notice via e-mail or postal mail. You shall be responsible for reviewing and becoming familiar with any such modifications. Your use of the Services following such notification constitutes your acceptance of the terms and conditions of this Agreement as modified. If at any time you do not agree to these terms and conditions, you must terminate your use of the Services. You will still remain liable for any obligations incurred or charges accrued on or before the date of termination.
         <br />
         <br />
         You certify to Shoprises that you are at least 18 years of age. You also certify that you are legally permitted to use the Services and access the Site. If you are entering into this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind such entity to this agreement, in which case the terms "you" or "your" shall refer to such entity. This Agreement is void where prohibited by law, and the right to use the Services is revoked in such jurisdictions.
         <br />
         <br />
         Shoprises will use reasonable efforts to ensure that the Services are available twenty-four hours a day, seven days a week. However, there will be occasions when the Services will be interrupted for maintenance, upgrades and emergency repairs or due to failure of telecommunications links and equipment. Every reasonable step will be taken by Shoprises to minimize such disruption where it is within Shoprises's reasonable control. Shoprises will not be liable in any event to you or any other party for any suspension, modification, discontinuance or lack of availability of the Site, the Services, your Customer Data or Results (as defined herein) or other content.
      </div>

      <div className='title'>2. CONTENT</div>
      <div className="text">
         You shall not store any significant portion of the Content in any form. Copying or storing of any Content for other than personal, noncommercial use is expressly prohibited without prior written permission from Shoprises, or from the copyright holder identified in such Content's copyright notice. All trademarks, service marks, and trade names which appear on the Services are proprietary to Shoprises or third parties. You shall abide by all copyright notices and restrictions contained in any Content accessed through the Services.
         <br />
         <br />
         You shall not store any significant portion of the Content in any form. Copying or storing of any Content for other than personal, noncommercial use is expressly prohibited without prior written permission from Shoprises, or from the copyright holder identified in such Content's copyright notice. All trademarks, service marks, and trade names which appear on the Services are proprietary to Shoprises or third parties. You shall abide by all copyright notices and restrictions contained in any Content accessed through the Services.
      </div>

      <div className='title'>3. DMCA</div>
      <div className="text">
         In accordance with the DMCA, we've adopted the policy below toward copyright infringement. We reserve the right to (1) block access to or remove material that we believe in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates, content providers, members or users and (2) remove and discontinue service to repeat offenders.
         <br />
         <br />
         Remember that your use of Shoprises's Services is at all times subject to the Terms of Use, which incorporates this Copyright Dispute Policy. Any terms we use in this Policy without defining them have the definitions given to them in the Terms of Use.
         <br />
         <br />
         At Shoprises, we are very excited to support our Shoprises merchants and to help them produce great custom products and a great experience for their buyers. In our efforts, we are also committed to protecting the rights of individuals and companies with respect to the content published on the Shoprises service.
         <br />
         <br />
         Third party rights are based on a range of laws and legal standards that can be confusing. Those rights are each very different from the other and are often misunderstood. We have created this guide to provide very high-level information about certain types of legal rights and to help Shoprises merchants understand what is permissible on the service.
         <br />
         <br />  
         Shoprises supports the protection of intellectual property and asks Shoprises merchants to do the same. It's our policy to respond to all notices of alleged copyright infringement. If someone believes that one of our merchants is infringing their intellectual property rights, they can send a DMCA Notice to Shoprises's designated agent using our form. Upon receiving a DMCA Notice, we may remove or disable access to the Materials claimed to be a copyright infringement. Once provided with a notice of takedown, the merchant can reply with a counter notification using our form if they object to the complaint. The original complainant has 14 business days after we receive a counter notification to seek a court order restraining the merchant from engaging in the infringing activity, otherwise we restore the material. For more information, see our DMCA Notice and Takedown Procedure.
         <br />
         <br />
      </div>

      <div className='title'>4. THIRD PARTY SITES AND SERVICES</div>
      <div className="text">
         Users of Shoprises may gain access from the Services to third party sites on the Internet. Third party sites or services are not within the supervision or control of Shoprises. Shoprises makes no representations or warranties about any third party site or resource, and does not endorse the products or services offered by third parties. Shoprises disclaims all responsibility and liability for content on third party websites. You hereby irrevocably waive any claim against Shoprises with respect to third party content. Third party providers of ancillary services may require your agreement to additional or different license or other terms prior to your use or access of their sites or services. Any such agreement shall not in any way modify your Agreement here with Shoprises.
      </div>

      <div className='title'>5. TERMINATION FEES</div>
      <div className="text">
         Neither party may terminate the Services at any time by notifying the other party by any means. Shoprises may also terminate or suspend any and all Services immediately, without prior notice or liability, if you breach any of the terms or conditions of this Agreement. Any fees paid hereunder are non-refundable. Upon any such termination, your right to use the Services and access Results will immediately cease. All provisions of this Agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnification obligations, and limitations of liability.
         <br />
         <br />
         Shoprises may charge a monthly subscription fee for use of the Services. In the event you purchase paid Services, your credit card will be charged based on our policy. You may cancel your Shoprises subscription at any time, but once you have been charged a subscription fee, there are no refunds, partial or in full, for that fee.
      </div>

      <div className='title'>6. WARRANTY DISCLAIMER</div>
      <div className="text">
      Shoprises has no special relationship with or fiduciary duty to you. You acknowledge that Shoprises has no control over, and no duty to take any action regarding: which users gain access to the Services; what Results you may obtain via the Services; what effects the Content may have on you; how you may interpret or use the Results; or what actions you may take as a result of having been exposed to the Content. You release Shoprises from all liability for your having acquired or not acquired Content and Results through the Services. Shoprises does not manage or control any business or individual that you may interact with through the Services, and Shoprises accepts no responsibility or liability for any act or omission by such entity. You hereby waive any and all legal or equitable rights or remedies you have or may have against Shoprises with respect to acts and omissions by such entities.
         <br />
         <br />
         The Services may contain, or direct you to sites containing, information that some people may find offensive or inappropriate. Shoprises makes no representations concerning any content contained in or accessed through the Services, and Shoprises will not be responsible or liable for the accuracy, copyright compliance, legality or decency of material contained in or accessed through the Services.the services, content, results, site and any software are provided on an "as is" basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, title, fitness for a particular purpose or non-infringement. some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitations may not apply to you.
         <br />
         <br />
         to the fullest extent allowed by law, Shoprises disclaims any liability or responsibility for the accuracy, reliability, availability, completeness, legality or operability of the material provided through the services. by using the services, you acknowledge that Shoprises is not responsible or liable for any harm resulting from (1) use of the services; (2) downloading information contained on the services; (3) unauthorized disclosure of images, information or data through the services; and (4) the inability to access or retrieve any results from the services, including, without limitation, harm caused by viruses or any similar destructive program.
      </div>

      <div className='title'>7. PRIVACY</div>
      <div className="text">
         Please review our Privacy Policy, which governs the use of personal information by Shoprises and to which you agree to be bound as a user of the Services.
      </div>
    </div>
  </Layout>
)

export default TermsofService
